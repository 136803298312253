import { createI18n } from 'vue-i18n'
import en from './en-US/en-US'
import zh from './zh-CN/zh-CN'

const messages = {
  en: {
    ...en
  },
  zh: {
    ...zh
  }
}
const defaultLocale = navigator.language.slice(0, 2)? navigator.language.slice(0, 2) : 'zh';

const i18n = createI18n({
  locale: defaultLocale, // 设置语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages
})

export default i18n
