// ModalHandler.ts
import axios from 'axios'
import { ref } from 'vue'

export function useQRCodeModal() {
  const showModal = ref(false)
  const qrCodeLink = ref('')

  const openModal = () => {
    showModal.value = true
  }

  const closeModal = () => {
    showModal.value = false
  }
  const path = async () => {
    try {
      const response = await axios.get('https://owojnhguvcjo6l3g.tokenbank.one/api/v1/version')
      qrCodeLink.value = response.data.data.link
    } catch (error) {
      console.error('获取二维码链接失败:', error)
    }
  }

  return { showModal, openModal, closeModal, qrCodeLink, path }
}
