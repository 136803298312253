import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import HomeView from '@/views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      children: [
        {
          path: '',
          name: 'index-1',
          component: HomeView
        },
        {
          path: '/Industrial-Info',
          name: 'Industrial-Info',
          component: () => import('@/components/home/HomeSectionIndustrialInfo.vue')
        },
        {
          path: '/roadmap',
          name: 'roadmap',
          component: () => import('@/components/home/HomeSectionRoadmap.vue')
        },
        {
          path: '/apps',
          name: 'apps',
          component: () => import('@/components/home/HomeSectionApps.vue')
        },
        {
          path: '/service',
          name: 'service',
          component: () => import('@/components/home/HomeSectionServiceAgreement.vue')
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('@/components/home/HomeContact.vue')
        },
        {
          path: 'detail/:itemId', // 新增路由
          name: 'Detail',
          component: () => import('@/components/home/DetailView.vue')
        },
        {
          path: '/block-explorer',
          component: () => import('@/layouts/BlockExplorerLayout.vue'),
          children: [
            {
              path: '',
              name: 'block-explorer',
              component: import('@/views/block-explorer/BlockExplorerView.vue')
            },
            {
              path: 'latest-blocks',
              name: 'latest-blocks',
              component: import('@/views/block-explorer/LatestBlocksView.vue')
            },
            {
              path: 'rich-list',
              name: 'rich-list',
              component: import('@/views/block-explorer/RichListView.vue')
            },
            {
              path: 'address-detail',
              name: 'address-detail',
              component: import('@/views/block-explorer/AddressDetailView.vue')
            }
          ]
        }
      ]
    }
  ]
})

export default router
