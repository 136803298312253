<template>
  <footer id="contact1">
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <p class="copyright">
              {{ $t('footer.content1') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
