<template>
  <div class="lang">
    <div class="selected">
      <img :src="selectedFlag" alt="" />
      <i class="fa fa-angle-down"></i>
    </div>
    <ul class="flag-list"  >
      <li>
        <a href="#" @click="changeLocale('en', 'en')">
          <img src="@/assets/images/flags/en.png" alt="" />
          <span>EN</span>
        </a>
      </li>
      <li>
        <a href="#" @click="changeLocale('zh', 'cn')">
          <img src="@/assets/images/flags/cn.png" alt="" />
          <span>CN</span>
        </a>
      </li>
      <!-- <li>
        <a href="#" @click="changeLocale('en')">
          <img src="@/assets/images/flags/br.png" alt="" />
          <span>BR</span>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script setup lang="ts">
import { getCurrentInstance, ref ,onMounted} from 'vue'
import flagEN from '@/assets/images/flags/en.png'
import flagCN from '@/assets/images/flags/cn.png'
let $this = getCurrentInstance()?.appContext.config.globalProperties as any
const selectedFlag = ref<string>('')

const setSelectedFlag = () => {
  switch ($this.$i18n.locale) {
    case 'en':
      selectedFlag.value = flagEN;
      break;
    case 'zh':
      selectedFlag.value = flagCN;
      break;
    default:
      selectedFlag.value = flagEN; // 默认情况下使用英语旗帜
  }
}


const changeLocale = (lang: string, flag: string) => {
  $this.$i18n.locale = lang
  if (flag === 'en') {
    selectedFlag.value = flagEN
  } else if (flag === 'cn') {
    selectedFlag.value = flagCN
  }
}

onMounted(()=>{
  setSelectedFlag();
})
// 清除全局事件监听避免内存泄漏

</script>
