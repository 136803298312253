<script setup lang="ts">
import { onMounted } from 'vue'

onMounted(() => {
  // Language Flags Dropdown Toggle
  $('body').click(function (e) {
    var el = e.target

    if ($(el).parents('.flag-list').length || $(el).hasClass('flag-list')) return

    if ($('.flag-list').css('display') === 'block') {
      $('.flag-list').css('display', 'none')
      return
    }

    if ($(el).hasClass('selected') || $(el).parents('.selected').length) {
      $('.flag-list').css('display', 'block')
    }
  })
})
</script>

<template>
  <RouterView />
</template>
