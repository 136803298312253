//import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/scss/bootstrap.scss'
// 导入 Bootstrap 样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/scss/purple.scss'
import 'vue-qr'

import 'element-plus/theme-chalk/index.css'
import '@/assets/css/font-awesome.min.css'
/* import ElementPlus from 'element-plus' */
import { createApp } from 'vue'
import { createPinia } from 'pinia'
/* import { createI18n } from 'vue-i18n' */
import i18n from './lang'

import App from './App.vue'
import router from './router'

const app = createApp(App)
app.use(i18n)
app.use(createPinia())
app.use(router)

app.mount('#app')
