<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import PJS from 'particles.js-es'

import { purpleWelcomeParticles } from '@/settings/particles'

const welcomeRef = ref<HTMLElement>()
const welcomeHeight = ref('auto')

onMounted(() => {
  initParticles()
  handleWindowResize()
  window.addEventListener('resize', handleWindowResize)
})

onBeforeUnmount(() => {
  destroyParticles()
  window.removeEventListener('resize', handleWindowResize)
})
declare global {
  interface Window {
    particlesJS: any
  }
}

function handleWindowResize() {
  const width = window.innerWidth

  if (width > 991) {
    const height = window.innerHeight
    welcomeHeight.value = `${height - 80}px`
  } else {
    welcomeHeight.value = 'auto'
  }

  if (welcomeRef.value) {
    refreshParticles()
  }
}

function initParticles() {
  PJS.init('welcome-1', purpleWelcomeParticles)
}

function destroyParticles() {
  PJS.destroy('welcome-1')
}

function refreshParticles() {
  PJS.refresh('welcome-1', purpleWelcomeParticles)
}
</script>

<template>
  <section
    class="welcome-area bg-top-right"
    id="welcome-1"
    ref="welcomeRef"
    :style="{ height: welcomeHeight }"
  >
    <div class="header-token basic">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
            <h1>{{ $t('welcome.title') }}</h1>
            <p>
              {{ $t('welcome.content') }}
            </p>
            <div>
              <a href="/apps" class="btn-secondary-box">
                {{ $t('welcome.download') }}
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
            <div class="box">
              <video controls poster="@/assets/images/logos/333.png">
                <source src="https://www.tokenbank.one/video/preview.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-to">
      <a href="#what-is-ico">
        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
      </a>
    </div>
  </section>
</template>

<style scoped>
@import '../../scss/Model.scss'; /*导入scss*/
</style>
