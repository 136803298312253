<template>
  <section class="section bg-bottom" id="what-is-ico">
    <div class="container">
      <!-- ***** Features Items Start ***** -->
      <div class="row m-bottom-70">
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div
            class="features-elliptical elliptical-bottom"
            data-scroll-reveal="enter right move 30px over 0.6s after 0.2s"
          >
            <div class="icon">
              <i class="fa fa-cube"></i>
            </div>
            <h5 class="features-title">{{ $t('whatisico.title1') }}</h5>
            <p>{{ $t('whatisico.content1') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div
            class="features-elliptical elliptical-top"
            data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
          >
            <div class="icon">
              <i class="fa fa-lock"></i>
            </div>
            <h5 class="features-title">{{ $t('whatisico.title2') }}</h5>
            <p>{{ $t('whatisico.content2') }}</p>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12"
          data-scroll-reveal="enter right move 30px over 0.6s after 0.6s"
        >
          <div class="features-elliptical">
            <div class="icon">
              <i class="fa fa-server"></i>
            </div>
            <h5 class="features-title">{{ $t('whatisico.title3') }}</h5>
            <p>
              {{ $t('whatisico.content3') }}
            </p>
          </div>
        </div>
      </div>
      <!-- ***** Features Items End ***** -->

      <!-- ***** About Start ***** -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
          <div class="left-heading">
            <h2 class="section-title">{{ $t('whatisico.title4') }}</h2>
          </div>
          <div class="left-text">
            <p class="dark">
              {{ $t('whatisico.content4') }}
            </p>
            <p>
              {{ $t('whatisico.content5') }}
            </p>
          </div>

          <div>
            <a href="/apps" class="btn-secondary-box1">
              {{ $t('welcome.download') }}
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
          <img
            src="@/assets/images/theme-1-about.svg"
            class="img-fluid float-right"
            alt="Arda ICO"
          />
        </div>
      </div>
      <!-- ***** About End ***** -->
    </div>
  </section>
</template>

<script setup lang="ts">
import { useQRCodeModal } from '../ModalHandler'
import { onMounted } from 'vue'
import VueQr from 'vue-qr/src/packages/vue-qr.vue' // 确保路径正确
onMounted(() => {
  path()
})

const { qrCodeLink, showModal, openModal, closeModal, path } = useQRCodeModal()
</script>
<style scoped>
@import '../../scss/Model.scss'; /* 假设 SCSS 文件在同一目录下 */
</style>
