import type router from '@/router';
<template>
  <ul class="nav">
    <li>
      <!-- <a href="#what-is-ico">{{ $t('messages.WHATISICO') }}</a> -->
      <router-link to="/#welcome-1">{{ $t('messages.WHATISICO') }}</router-link>
    </li>
    <!---->
    <li>
      <router-link to="/Industrial-Info">{{ $t('messages.Tokensale') }}</router-link>
    </li>
    <li>
      <router-link to="/roadmap">{{ $t('messages.ROADMAP') }}</router-link>
    </li>
    <li>
      <router-link to="/apps">{{ $t('messages.APPS') }}</router-link>
    </li>
    <!--   <li>
      <router-link to="/#team">{{ $t('messages.TEAM') }}</router-link>
    </li> -->
    <!-- <li>
      <a href="#faq">{{ $t('messages.FAQ') }}</a>
    </li> -->
    <li>
      <router-link to="/service">{{ $t('messages.Service') }}</router-link>
    </li>
    <li>
      <router-link to="/contact">{{ $t('messages.CONTACT') }}</router-link>
    </li>
    <li>
      <router-link to="#" class="btn-nav-box">{{ $t('messages.SIGNUP') }}</router-link>
    </li>
  </ul>
  <a class="menu-trigger">
    <span>Menu</span>
  </a>
</template>
