<template>
  <section class="section bg-bottom" id="team">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-heading">
            <h2 class="section-title">{{ $t('Team.title') }}</h2>
          </div>
        </div>
        <div class="offset-lg-3 col-lg-6">
          <div class="center-text">
            <p>
              {{ $t('Team.content') }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-md-3 col-sm-6 col-12"
          data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s"
        >
          <div class="team-round">
            <div class="profile">
              <div class="img">
                <img src="@/assets/images/1.jpg" alt="" />
                <ul class="social">
                  <li>
                    <a href="#"><i class="fa fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <h3 class="team-name">{{ $t('Team.name') }}</h3>
            <span>{{ $t('Team.job') }}</span>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 col-12"
          data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s"
        >
          <div class="team-round">
            <div class="profile">
              <div class="img">
                <img src="@/assets/images/2.jpg" alt="" />
                <ul class="social">
                  <li>
                    <a href="#"><i class="fa fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <h3 class="team-name">{{ $t('Team.name1') }}</h3>
            <span>{{ $t('Team.job1') }}</span>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 col-12"
          data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s"
        >
          <div class="team-round">
            <div class="profile">
              <div class="img">
                <img src="@/assets/images/3.jpg" alt="" />
                <ul class="social">
                  <li>
                    <a href="#"><i class="fa fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <h3 class="team-name">{{ $t('Team.name2') }}</h3>
            <span>{{ $t('Team.job2') }}</span>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 col-12"
          data-scroll-reveal="enter bottom move 50px over 0.6s after 0.8s"
        >
          <div class="team-round">
            <div class="profile">
              <div class="img">
                <img src="@/assets/images/4.jpg" alt="" />
                <ul class="social">
                  <li>
                    <a href="#"><i class="fa fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <h3 class="team-name">{{ $t('Team.name3') }}</h3>
            <span>{{ $t('Team.job3') }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
