<script setup lang="ts"></script>

<template>
  <HeaderWrapper>
    <HomeNav />
  </HeaderWrapper>

  <HomeSectionWelcome />

  <HomeSectionWhatIsIco />

  <!--   <HomeSectionTokenSale /> -->

  <!--   <HomeSectionRoadmap />

  <HomeSectionApps /> -->

  <HomeSectionTeam />

  <!--   <HomeSectionTelegramParallax /> -->

  <!-- <HomeSectionFaq /> -->

  <!--  <HomeSectionServiceAgreement /> -->
  <HomeFriends />
  <HomeFooter />
</template>
