export default {
  messages: {
    WHATISICO: '首頁',
    Tokensale: '行業資訊',
    ROADMAP: '路線圖',
    APPS: '應用程式',
    TEAM: '團隊',
    FAQ: '公告',
    CONTACT: '聯繫',
    SIGNUP: '登陸',
    Service: 'TOKEN BANK 服務協定',
    /* img: '@/assets/images/flags/cn.png' */
  },
  welcome: {
    title: 'TOKEN BANK 去中心化區塊鏈多簽錢包',
    content: ' TOKEN BANK保護你的財產安全，讓合作變得更加簡單',
    download: '下载',
    rtitle: 'ICO 售賣已經開放'
  },
  whatisico: {
    title1: '輕鬆的代幣集成',
    content1: '智慧與生命的疾病。',
    title2: '高級安全性',
    content2: '你的心永遠是純潔的。',
    title3: '分散',
    content3: '前舌弓，後舌弓，右舌弓，右舌弓。 在洛倫馬萊蘇阿達。',
    title4: '令人信服的價值主張',
    content4: 'Token Bank是一種開源的、類似比特幣的數字貨幣，它使用工作量證明腳本演算法。',
    content5:
      '創世區塊於2014年3月1日開採。 可開採的ARD總數為245,465,283。 Arda的開採分為幾個時期：每個時期開採36000塊硬幣，目標是持續大約25天。'
  },
  Tokensale: {
    title: '行業資訊',
    content: '在黑暗中尋找，在生命中尋找。 這是一個很好的機會。',
    start: '開始',
    PreSale: '售前',
    SoftCap: '現在',
    TokenSupply: '代幣供應',
    AcceptedTokens: '接受的代幣',
    ProjectProtocol: '協定',
    CirculationSupply: '流通供應',
    MaximumCap: '最大上限',
    title2: '資訊',
    title1: '公告',
    button: '購買代幣',
    days: '日',
    hours: '时',
    minutes: '分',
    seconds: '秒'
  },
  Roadmap: {
    title: '路線圖',
    content: ' 獅子座的烏鴉和獅子座的湖泊。 無虐待和強迫性侵權行為.',
    content1:
      '開發適用於 Windows 的桌面應用程式，Android 錢包。 連接側鏈+安全沙箱。 有機會在ARD平臺上創建去中心化應用程式。',
    content2:
      '運行委託系統。 一旦平臺上的代理系統啟動，有關如何啟動代理的文檔將在常見問題解答部分提供。',
    content3: '安達在交易所的安置。 放置ARD的交易所清單將不斷更新，請查看我們的網站。',
    content4: '更新代表們的共識。 通過向投票支持他們的人分配代表收入的一部分來增加權力下放。',
    content5:
      '將額外的加密貨幣添加到錢包中。 優先：比特幣、萊特幣、達世幣、Zcash和門羅幣。 其餘的將在投票期間添加。',
    content6: '創建 DAO 模組。 通過設立一個特殊的決策和預算管理部門來提高社區的效率。'
  },
  FAQ: {
    title: '公告',
    content: '在黑暗中尋找，在生命中尋找。 這是一個很好的機會。'
  },
  APPS: {
    title: '應用程式',
    content: '兩個人的身體各不相同',
    ARDWallet: 'TB 錢包',
    Transactioninfo: '交易資訊',
    Myaccount: '我的帳戶',
    BlockchainExplorer: '區塊鏈瀏覽器',
    Transfer: '轉移',
    ConnectedpeerstonetworkARD: '將對等體連接到網路 TB'
  },
  Team: {
    title: '團隊',
    content: '在黑暗中尋找，在生命中尋找。 這是一個很好的機會。',
    name: '蘭斯·柏格羅爾',
    job: '首席執行官兼區塊鏈負責人',
    name1: '布萊恩·孜然',
    job1: '首席技術官兼高級開發人員',
    name2: '傑克遜鍋',
    job2: '區塊鏈應用程式開發人員',
    name3: '道格拉斯·萊菲',
    job3: '社區管理'
  },
  Telegram: {
    title: '在Telegram上加入我们',
    content: '在黑暗中寻找，在生命中寻找。 这是一个很好的机会。',
    button: '在Telegram上加入我们'
  },
  footer: {
    title: '合作機構',
    title2: '聯繫我們',
    content: '在黑暗中尋找，在生命中尋找。 這是一個很好的機會。',
    com: 'sercive@tokenbank.one',
    join: '推特:',
    title1: '說點什麼',
    name: '名字、姓氏',
    email: '郵箱',
    message: '消息',
    button: '',
    content1: '2024 Copyright © TokenBank '
  },
  service: {
    title: 'TOKEN BANK 服務協定',
    content:
      '以下條款構成您與Token Bank之間具有法律約束力的協定。 您在使用本平台或通過本平臺提供的Token Bank服務時適用以下條款。 在使用本平台服務之前，請仔細閱讀本《服務協定》。 如需要使用本平台服務，請完全理解、接受、同意本《服務協定》，並同意我們發佈的任何後續追加更新的附加條款、規則和參與條件。 如果您不同意本《服務協定》，則您不得使用本平台服務。',
    content1:
      '我們在此提醒您在使用本平臺提供的Token Bank移動應用（以下簡稱“TokenBank”或“本軟體”，可在各移動應用平臺上下載，包括但不限於Google Play Store和Apple App Store），在下載之前，請認真閱讀本《服務協定》及本《服務協定》中提及的其他檔，特別是本《服務協定》中的“免責條款”，確保您充分理解本《 服務協定》中各項條款並自主考慮使用TokenBank的風險。',
    first: '一、關於本《服務協定》的確認',
    first1:
      '1. 您理解本《服務協定》及有關協定適用於Token Bank及Token Bank上我們及聯盟夥伴所開發和擁有的去中心化應用。',
    first2:
      '2. 您下載TokenBank軟體並創建帳號即視為您已經充分閱讀並接受本《服務協定》全部條款，本《服務協定》立即生效，對雙方具有約束力。 如果您不同意本《服務協定》條款，您必須立即停止使用Token Bank。 如果您已經下載了Token Bank，請立即刪除。',
    first3:
      '3. 在訪問或使用Token Bank時， 您同意接受本《服務協定》最新版本的約束。 本《服務協定》可由我們隨時更新，經修改的《服務協定》一經在我們的軟體或網站上公佈，立即自動生效，不再另行通知。 在我們公佈修改協定條款後，如果您不接受修改後的條款，請立即停止使用Token Bank，無論您是否有關注到最新的更新條款，本《服務條款》進行任何修改後繼續使用Token Bank即表示您接受這些修改後的《服務協定》。',
    second: '二、風險提示',
    second1:
      '1. 您瞭解並知悉，由於數位貨幣領域所使用的區塊鏈技術的特點，在使用Token Bank服務時，若您或您的交易方利用我們提供的工具進行轉帳交易，不符合區塊鏈規則的行為將有可能導致轉帳失敗（如餘額不足，手續費過低導致的無法打包等），我們並不保證交易會順利完成，且我們不承擔損害賠償責任。 如果出現「交易失敗」、「打包超時」等類似的異常資訊提示時，您應通過相關區塊鏈系統的官方途徑或其他的區塊鏈查詢工具進行再次確認，以避免重複轉帳的發生。 若數位貨幣轉到錯誤的鏈或位址，有可能會丟失資產，您理解區塊鏈操作具有的“不可逆”屬性，以及相關交易具有“不可撤銷”的特徵，由您及您的相對方自行承擔相應的風險後果。',
    second2:
      '2. 您瞭解並知悉Token Bank提供的資訊，行情或實時價格查看，總資產計算等功能，僅是抓取部分交易所的數位貨幣匯率資訊的搜尋結果，並不表示為最新行情或最佳報價。',
    third: '三、服務內容',
    third1:
      '1. 創建錢包帳號。 當您註冊帳號，您需要使用一個有效的郵箱來提交申請，並獲得一個郵箱位址為名的用戶帳號及具有唯一性的6位數的使用者身份證明（User Identification，簡稱UID）與它們所對應的您自已設置的登錄密碼。 具體使用方法請您參考《使用者指南》。',
    third2:
      '2. 轉帳、收款。 您可以使用Token Bank的轉帳、收款功能進行數字貨幣的管理，即運用簽名手段進行電子簽名，對相關區塊鏈的帳本進行修改。 轉帳是指付款方利用收款方的ENS功能變數名稱或區塊鏈地址進行轉帳操作，該「轉帳」行為涉及在相關區塊鏈系統的分散式帳本中對該交易的有效記錄（而非在TokenBank上實際交付或轉讓數字貨幣），基於區塊鏈系統交易」不可撤銷“的屬性，我們不能為您撤回或撤銷交易操作。',
    third3:
      '3. WEB3個人身份。 運用簽名手段進行電子簽名后，可以驗證您的數位身份，並在相應的WEB3應用與服務上（比如NFT，DEFI），確認及行使您做為所有人所擁有的權益且無需與實際身份相關聯。',
    third4:
      '4. 好友功能。 您可以通過與好友互發邀請碼或掃描二維碼的方式添加好友，該添加行為只限於單方面主動添加有效。 被動接受方不會自動加您為好友除非您的好友主動執行添加您。 好友之間可以互相快捷轉帳。 當使用我們的好友轉帳功能時，我們提供了更加安全的收款位址的再次核對確認功能。',
    third5:
      '5. 交易記錄。 我們將通過區塊鏈系統拷貝您全部或部分的交易記錄，方便您的查詢，但我們的記錄僅用於參考。 您應以區塊鏈系統的鏈上最新交易記錄為準，在有必要核實時以即時鏈上查詢數據為準。 ',
    third6:
      '6. 資產管理。 您可以通過TokenBank參與有關的defi或其他第三方提供的聯合採礦等資產管理專案。',
    third7:
      '7. 您可以使用Token Bank查看第三方提供的數位貨幣匯率價格。 Token Bank通過抓取相應的數位貨幣匯率資訊並展示在TokenBank中的“行情”版塊。 ',
    third8: '8. 管理數位代幣。 您可以從Token Bank操作介面添加、保管或移除Token Bank所支持的數位代幣。',
    third9: '9. 數字貨幣的交易。',
    third10: '10. 其他我們認為有必要提供的服務，將持續更新與豐富。 ',
    notice:
      '請您知悉：無法暫停服務！ 由於代碼的開源去中心化屬性，我們無法凍結，暫停或者限制某位已註冊（代表已同意所有服務條款）使用者對TokenBank軟體的操作。',
    fourth: '四、免責條款',
    fourth1:
      '1.秉承著區塊鏈的去中心化特點，並為了保護您數字貨幣安全，我們提供的是去中心化服務，無任何由TokenBank向使用者收取的費用，區別於銀行業等金融機構。 您瞭解並接受，我們不承擔以下責任： ',
    fourth2:
      '（1）註冊成功后，您需要妥善保存記錄好您註冊時使用的電子郵件並使其保持始終處於您的控制之下，以備忘記賬號或登錄密碼時，可以通過郵箱重置密碼。 由於忘記郵箱位址或郵箱無法收到驗證碼原因導致的帳號遺失或無法重置登錄密碼，我們不承擔任何責任。',
    fourth3:
      '（2）Token Bank是一個將私鑰、助記詞或Keystore等私鑰檔去中心碎片化加密存儲的工具軟體，無法匯出您的完整錢包私鑰、助記詞或Keystore，但是您可以轉入或轉出所有的數字資產。 無法獲得錢包私鑰、助記詞或Keystore文件的責任我們不承擔。',
    fourth4:
      '（3）Token Bank需要您提供多重身份驗證才能完成電子簽名，您應當自行妥善保管Token Bank所需要的多重簽名手段及其關聯的設備（如手勢圖形的圖片或截圖，密保問題答案文檔，記事本，WORD，EXCEL文檔、簡訊、微信、QQ留言記錄或保存了以上內容的手機、PC電腦、U盤、SIM卡、硬碟等）。 由於以上設備、資料失控或被他人盜取、掌握、監控導致的資產被轉移，我們均無法還原或找回;如使用者進行交易時操作失誤或失敗（包括但不限於輸錯轉帳位址、輸錯金額與數量，“交易失敗”或“打包超時”，“重複轉帳”，錢包餘額不足，交易礦工費不足，區塊鏈執行智慧合約代碼失敗，網路、設備等技術故障，區塊鏈網络擁堵、 故障等原因引起交易被拋棄，用戶的設備缺陷或網路漏洞導致的信息洩露等），因區塊鏈技術的特點，我們均無法取消交易，且我們亦無法對此承擔任何責任。',
    fourth5:
      '（4）Token Bank軟體雖然已經過嚴格的安全審查及代碼開源，但仍然可能會存在BUG或未知的錯誤，由此引起的責任我們不承擔。',
    fourth6:
      '2. Token Bank所能夠提供的數位代幣管理服務並未包括所有已存在的數位代幣，您可以通過添加TokenBank上支援的鏈上的合約地址進行數位代幣添加。 請在充分瞭解並確認數位代幣合約信息的基礎上進行操作，對不支援的鏈或智慧合約執行的操作我們不承擔任何責任。 ',
    fourth7:
      '3. Token Bank上集成的DApp包括我們自主擁有的DApp和第三方平臺提供的DApp。 對於第三方平臺提供的DApp，Token Bank僅為用戶進入DApp提供搜索和區塊鏈瀏覽器功能，不對第三方DApp的功能或服務品質提供任何擔保。 您在第三方DApp上接受服務或進行交易前應自行判斷和評估該第三方DApp提供的服務或交易是否存在風險。',
    fourth8:
      '4. Token Bank上提供的轉帳服務是基於區塊鏈規則的數位簽名，我們不瞭解付款方與接收方的真實使用者身份，個人資訊，聯繫方式等，亦不搜集，不保存任何使用者資訊，我們無法明確消費者是自然人或組織; 使用者提交的相關簽名手段是通過區塊鏈或其他分散式方案存儲的碎片化資訊，除使用者提交多重簽名授權后可以調取用於數字簽名外，其他任何手段無法獲取，因此也無法向任何第三方提供任何參與方的資訊，對此我們不承擔任何責任。',
    fourth9:
      '5. 您知悉Token Bank僅向您提供工具，在您使用Token Bank完成轉帳或收款后，我們即完成了當次服務的所有義務，我們對其他相關糾紛爭議，不承擔任何責任。',
    fourth10:
      '6. 由不可抗力因素導致的不確定時間段的無法提供服務，我們不承擔任何責任，但我們承諾盡最大的努力在停止服務前您的資產可以得到轉移。 不可抗力包括但不限於以下：',
    fourth11: '（1）罷工、暴亂、戰爭，動亂、恐怖行為等；',
    fourth12: '（2）自然災害如洪水、火災、龍捲風、地震和颶風等；',
    fourth13: '（3）引發國家或國際關注的任何流行病、疫情或突發公共衛生事件等；',
    fourth14: '（4）政府、國家機構或當局制定的阻止Token Bank在平臺上提供相關服務的法律或法規等；',
    fourth15: '（5）駭客攻擊、計算機病毒、網路擁堵、傳輸、通信設備故障或計算機設施出現技術故障等；',
    notice1: '您在使用Token Bank時瞭解並接受：',
    next: '五、解釋權歸屬',
    next1:
      '在您使用Token Bank后，對於本《服務協定》上述描述中未明確的有可能產生岐義，爭議，糾紛或責任不清的情形，最終解釋權歸屬於Token Bank開發團隊所有。'
  }
}
