<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

onMounted(() => {
  // Menu Dropdown Toggle
  $('.menu-trigger').on('click', function () {
    $(this).toggleClass('active')
    $('.header-area .nav').slideToggle(200)
  })

  // Fix
  $('.header-area .nav').on('click', 'li a', function () {
    if ($('.menu-trigger').hasClass('active')) {
      $('.menu-trigger').removeClass('active')
      $('.header-area .nav').slideUp(200)
    }
  })

  // Menu elevator animation
  $<HTMLAnchorElement>('a[href*=\\#]:not([href=\\#])').on('click', function () {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      if (target.length) {
        var width = $(window).width()
        if (width && width < 991) {
          $('.menu-trigger').removeClass('active')
          $('.header-area .nav').slideUp(200)
        }
        $('html,body').animate(
          {
            scrollTop: target.offset()!.top - 30
          },
          700
        )
        return false
      }
    }
  })

  // Header Scrolling Set White Background
  $(window).scroll(function () {
    var width = $(window).width()
    if (width && width > 991) {
      var scroll = $(window).scrollTop()
      if (scroll && scroll >= 30) {
        $('.header-area').addClass('header-sticky')
        $('.header-area .dark-logo').css('display', 'block')
        $('.header-area .light-logo').css('display', 'none')
      } else {
        $('.header-area').removeClass('header-sticky')
        $('.header-area .dark-logo').css('display', 'none')
        $('.header-area .light-logo').css('display', 'block')
      }
    }
  })
})

onUnmounted(() => {
  $('.menu-trigger').off('click')
  $('a[href*=\\#]:not([href=\\#])').off('click')
})
</script>

<template>
  <header class="header-area">
    <div class="container">
      <div class="row">
        <div class="col-12 position-relative">
          <nav class="main-nav">
            <Logo />

            <LangSelect />

            <slot />
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
