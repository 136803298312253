export default {
  messages: {
    WHATISICO: 'WHAT ICO',
    Tokensale: 'Industrial Info',
    ROADMAP: 'ROADMAP',
    APPS: 'APPS',
    TEAM: 'TEAM',
    FAQ: 'FAQ',
    CONTACT: 'CONTACT',
    SIGNUP: 'SIGN UP',
    Service: 'AGREEMENT',
    img: '@/assets/images/flags/en.png'
  },
  welcome: {
    title: 'ARDA Decentralized Blockchain Platform',
    content:
      ' Arda makes it easy creation and deployment of decentralized applications, that can bedownloaded by end-users from the application store',
    download: 'Download Whitepaper',
    rtitle: 'ICO SALE IS OPEN'
  },
  whatisico: {
    title1: 'Easy Token Integration',
    content1: 'Morbi pharetra sapien ut mattis viverra. Curabitur sit amet mattis magna lipsum.',
    title2: 'Advanced Security',
    content2: 'Donec pellentesque turpis utium lorem imperdiet semper. Ut mat viverra mattis.',
    title3: 'Decentralized',
    content3:
      'Proin arcu ligula, malesuada id tincidunt laoreet, facilisis at justo. Sed at lorem malesuada.',
    title4: 'A compelling value proposition.',
    content4:
      'ARDA (ARD) is an open source, Bitcoin-like digital currency which uses a proof of work script algorithm.',
    content5:
      'The genesis block was mined on March 1st, 2014. The total number of mineable ARD is 245,465,283. The mining of Arda is divided into Epochs: each Epoch mines 36000 blocks of coins and is targeted to last approximately 25 days.',
    download: 'Download Whitepaper'
  },
  Tokensale: {
    title: 'Industrial Info ',
    content:
      'Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.',
    start: 'Start',
    PreSale: 'Pre Sale',
    SoftCap: 'Soft Cap',
    TokenSupply: 'Token Supply',
    AcceptedTokens: 'Accepted Tokens',
    ProjectProtocol: 'Project Protocol',
    CirculationSupply: 'Circulation Supply',
    MaximumCap: 'Maximum Cap',
    title2: 'information',
    title1: 'announcement',
    button: 'PURCHASE TOKEN NOW',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
  },
  Roadmap: {
    title: 'Roadmap',
    content:
      ' Cras at leo et lacus laoreet euismod. Nulla malesuada tortor ac scelerisque sollicitudin.',
    content1:
      'Development of a desktop application for Windows, Android Wallet. Connecting Sidechain + Secure Sandbox. Opportunity to create decentralized applications on the ARD platform.',
    content2:
      'Running the delegate system. As soon as the system of delegates on the platform is launched, the documentation on how to start the delegate will be available in the FAQ section',
    content3:
      'Placement of Arda on exchanges. The list of exchanges that placed ARD will be constantly updated, please, kindly check our site.',
    content4:
      "Updating the consensus of the Delegates. Increase in decentralization, through the distribution of a portion of the delegate's income to those who vote for them.",
    content5:
      'Adding additional crypto currencies to the wallet. Priority: Bitcoin, Litecoin, Dash, Zcash and Monero. The rest will be added during voting.',
    content6:
      'Creating a DAO module. Increase the effectiveness of the community through the creation of a special section of decision-making and budget management.'
  },
  FAQ: {
    title: 'FAQ',
    content:
      'Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.'
  },
  APPS: {
    title: 'APPS',
    content:
      'Maecenas et consequat nunc. Duis faucibus orci eu varius sagittis. In quam elit, euismod a urna quis',
    ARDWallet: 'ARD Wallet',
    Transactioninfo: 'Transaction info',
    Myaccount: 'My account',
    BlockchainExplorer: 'Blockchain Explorer',
    Transfer: 'Transfer',
    ConnectedpeerstonetworkARD: 'Connected peers to network ARD'
  },
  Team: {
    title: 'Team',
    content:
      'Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.',
    name: 'Lance Bogrol',
    job: 'CEO & Lead Blockchain',
    name1: 'Brian Cumin',
    job1: 'CTO & Senior Developer',
    name2: 'Jackson Pot',
    job2: 'Blockchain App Developer',
    name3: 'Douglas Lyphe',
    job3: 'Community Management'
  },
  Telegram: {
    title: 'Join Us On Telegram',
    content:
      'Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.',
    button: 'Join Us On Telegram'
  },
  footer: {
    title: 'Collaborating Institution',
    content:
      'Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.',
    title2: 'Contact Us',
    com: 'sercive@tokenbank.one',
    join: 'twitter:',
    title1: 'Say Something',
    name: 'Name, surname',
    email: 'Email',
    message: 'Message',
    button: 'SUBMIT',
    content1: '2024 Copyright © TokenBank '
  },
  service: {
    title: 'TOKEN BANK Service Agreement ',
    content:
      ' The following terms constitute a legally binding agreement between you and TokenBank.  The following terms apply to your use of the TokenBank Services available on or through the Platform.  Please read this Service Agreement carefully before using the Platform Services.  If you need to use this platform service, please fully understand, accept and agree to this Service Agreement, and agree to any additional terms, rules and conditions of participation that we publish.  If you do not agree to this Service Agreement, you may not use the Platform Services. ',
    content1:
      'We would like to remind you that when using TokenBank mobile app provided by this platform (hereinafter referred to as "TokenBank" or "Software", which can be downloaded on various mobile application platforms, including but not limited to Google Play Store and Apple App Store). Before downloading, please carefully read this Service Agreement and other documents mentioned in this Service Agreement, especially the "Disclaimer" in this Service Agreement. Ensure that you fully understand the terms of this Service Agreement and consider the risks of using TokenBank at your own discretion.  ',
    first: 'Ⅰ. Confirmation of this Service Agreement ',
    first1:
      ' 1. You understand that this Service Agreement and related agreements apply to TokenBank and decentralized applications developed and owned by us and our affiliate partners on TokenBank. ',
    first2:
      ' 2. By downloading TokenBank software and creating an account, you are deemed to have fully read and accepted all the terms of this Service Agreement, which shall take effect immediately and be binding on both parties.  If you do not agree with the terms of this Service Agreement, you must immediately stop using TokenBank.  If you have downloaded TokenBank, please delete it immediately. ',
    first3:
      ' 3. By accessing or using TokenBank, you agree to be bound by the latest version of this Agreement.  This Service Agreement may be updated by us at any time, and the revised Service Agreement shall automatically take effect as soon as it is published on our software or website without further notice.  If you do not accept the revised terms after we publish the revised terms, please stop using TokenBank immediately, regardless of whether you have concerns about the latest updated terms, and continue to use TokenBank after any modification of these Terms of Service means that you accept these revised Terms of Service. ',
    second: ' II. Risk warning ',
    second1:
      ' 1. You understand and understand that due to the characteristics of blockchain technology used in the digital currency field, when using TokenBank services, if you or your transaction party uses the tools provided by us to make a transfer transaction, behaviors that do not comply with the rules of blockchain may lead to transfer failure (such as insufficient balance, low handling fees, etc.). We do not guarantee that the transaction will be completed smoothly, and we do not assume liability for damages.  If there is a similar abnormal message prompt such as "transaction failure" or "package timeout", you should confirm it again through the official channel of the relevant blockchain system or other blockchain query tools to avoid duplicate transfers.  If the digital currency is transferred to the wrong chain or address, it is possible to lose the asset. You understand that the blockchain operation has the "irreversible" property and the related transaction has the "irrevocable" characteristic, and the corresponding risk consequences are borne by you and your counterpart. ',
    second2:
      ' 2. You understand and know that TokenBank provides information, market or real-time price view, total asset calculation and other functions, which are only search results for digital currency exchange rate information on some exchanges, and do not represent the latest market or best offer. ',
    third: ' III. Service Content ',
    third1:
      ' 1. Create a wallet account.  When you register an account, you need to submit an application using a valid email address and obtain a user account with the name of the email address and a unique 6-digit User Identification (UID) and their corresponding login password.  Please refer to the User Guide for details. ',
    third2:
      " 2. Transfers, receipts.  You can use TokenBank's transfer and collection functions to manage digital currency, that is, use signature means to electronically sign and modify the books of related blockchain.  A transfer is an operation performed by the payer using the ENS domain name or blockchain address of the payee. This 'transfer' involves a valid record of the transaction in the distributed ledger of the relevant blockchain system (rather than the actual delivery or transfer of digital currency on TokenBank). Based on the 'irrevocable'nature of the blockchain system transaction, we cannot revoke or reverse the transaction operation for you. ",
    third3:
      ' 3. WEB3 personal identities.  Electronic signature by signature means verifies your digital identity and confirms and exercises your rights as an owner in the corresponding WEB3 applications and services (e.g. NFT, DEFI) without having to be associated with your actual identity. ',
    third4:
      ' 4. Friends function.  You can add friends by exchanging invitation codes with friends or scanning QR codes. This addition behavior is limited to unilateral active addition.  Passive recipients will not automatically add you as a friend unless your friend takes the initiative to add you.  Friends can quickly transfer money to each other.  When using our friend transfer feature, we provide a more secure re-check of the payment address .',
    third5:
      ' 5. The transaction log.  We will make copies of all or part of your transaction records through the blockchain system for your convenience, but our records are for reference only.  You shall be subject to the latest transaction records on the chain of the blockchain system, and the real-time query data on the chain shall prevail when necessary for verification.  ',
    third6:
      ' 6. Asset management.  TokenBank allows you to participate in asset management projects, such as joint mining, provided by the relevant defi or other third parties. ',
    third7:
      ' 7. You can use TokenBank to view exchange rates for digital currencies offered by third parties.  TokenBank captures the corresponding digital currency exchange rate information and displays it in the "Quotes" section of TokenBank.  ',
    third8:
      ' 8. Manage digital tokens.  You can add, store or remove TokenBank-supported digital tokens from the TokenBank interface. ',
    third9: ' 9. Digital currency transactions. ',
    third10: ' 10. Other services we deem necessary will be continuously updated and enriched.  ',
    notice:
      ' Please note: Service cannot be suspended!  Due to the open source decentralized nature of the code, we cannot freeze, suspend, or restrict the operation of TokenBank software by a registered user (who agrees to all terms of service). ',
    fourth: ' IV. Exemption clause ',
    fourth1:
      ' 1. Adhering to the decentralized characteristics of blockchain and in order to protect your digital currency security, we provide decentralized services without any fees charged by TokenBank to users, which is different from financial institutions such as banking.  You understand and accept that we are not responsible for:  ',
    fourth2:
      '（ 1) After successful registration, you need to properly save and record the e-mail you use when you register and keep it under your control, in case you forget your account or login password, you can reset your password through the mailbox.  We do not assume any responsibility for account loss or failure to reset login password due to forgetting email address or failure to receive Captcha. ',
    fourth3:
      ' (2) TokenBank is a tool software that stores private keys, mnemonic words or Keystore and other private key files in a decentralized and encrypted manner. It cannot export your complete wallet private keys, mnemonic words or Keystore, but you can transfer in or out all digital assets.  We are not responsible for not being able to obtain wallet keys, mnemonics or Keystore files. ',
    fourth4:
      ' (3) TokenBank requires you to provide multiple identity verification to complete the electronic signature. You should properly keep the multiple signature means required by TokenBank and its associated devices (such as pictures or screenshots of gesture graphics, secret answer documents, notepads, WORD, EXCEL documents, SMS, WeChat, QQ message records or mobile phones, PC computers, USB flash drives, SIM cards, hard disks, etc.).  Due to the above equipment, information out of control or stolen by others, control, monitoring caused by the transfer of assets, we are unable to restore or recover; If the user makes a mistake or fails when trading (Including but not limited to wrong transfer address, wrong amount and quantity,"transaction failure" or "package timeout","repeated transfer", insufficient wallet balance, insufficient transaction miner fee, failure of block chain to execute smart contract code, network, equipment and other technical failures, block chain network congestion, failures and other reasons caused by transactions abandoned, user equipment defects or network vulnerabilities caused by information leakage, etc.), due to the characteristics of block chain technology, we are unable to cancel transactions, We cannot accept any responsibility for this. ',
    fourth5:
      ' (4) Although TokenBank software has undergone strict security review and open source code, there may still be bugs or unknown errors, and we do not assume responsibility for them. ',
    fourth6:
      "2. TokenBank's digital token management service does not include all existing digital tokens, you can add digital tokens by adding contract addresses on the chain supported on TokenBank.  Please operate on the basis of fully understanding and confirming the information of the digital token contract. We do not accept any responsibility for the operation performed by the unsupported chain or smart contract. ",
    fourth7:
      ' 3. The DApps integrated on TokenBank include DApps owned by us and DApps provided by third-party platforms.  For DApps provided by third party platforms, TokenBank only provides search and blockchain browser functionality for users to access DApps and does not provide any guarantee for the functionality or quality of service of third party DApps.  You should judge and evaluate whether the services or transactions provided by the third party DApp are risky before accepting services or conducting transactions on the third party DApp. ',
    fourth8:
      ' 4. The transfer service provided on TokenBank is a digital signature based on blockchain rules. We do not know the real user identity, personal information, contact information, etc. of the payer and the recipient, nor do we collect or save any user information. We cannot be sure that the user is a natural person or organization; The relevant signature means submitted by the user is fragmented information stored through blockchain or other distributed schemes. Except for the digital signature that can be retrieved after the user submits the multi-signature authorization, no other means can be obtained, so we cannot provide any information of any participant to any third party, for which we do not assume any responsibility. ',
    fourth9:
      ' 5. You acknowledge that TokenBank only provides you with tools. After you complete the transfer or collection with TokenBank, we will complete all obligations for the current service. We will not be responsible for any other related disputes. ',
    fourth10:
      ' 6. We do not assume any responsibility for the inability to provide services for an indefinite period of time caused by force majeure factors, but we promise to do our best to transfer your assets before stopping the service.  Force majeure includes but is not limited to the following: ',
    fourth11: ' (1) Strikes, riots, wars, disturbances, terrorist acts, etc.; ',
    fourth12:
      ' (2) Natural disasters such as floods, fires, tornadoes, earthquakes and hurricanes ；',
    fourth13:
      ' (3) Any epidemic, epidemic or public health emergency of national or international concern ；',
    fourth14:
      ' (4) Laws or regulations enacted by governments, state agencies or authorities that prevent TokenBank from providing relevant services on the platform, etc. ；',
    fourth15:
      '(5) hacker attacks, computer viruses, network congestion, transmission, communication equipment failure or technical failure of computer facilities; ',
    notice1: ' When using TokenBank, you understand and accept: ',
    next: ' V. Ownership of the right of interpretation ',
    next1:
      ' After you use TokenBank, the TokenBank development team shall have the final right to interpret any ambiguity, dispute, dispute or unclear liability that may arise from the above description of this Service Agreement. '
  }
}
