<template>
  <footer id="contact2">
    <div class="footer-top1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="contact2">
              <h2 class="section-title" style="height: 10px">{{ $t('footer.title') }}</h2>
              <div class="contact-item">
                <div class="contact-item1">
                  <ul>
                    <li>
                      <p><img src="@/assets/friends/1212.svg" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/certik-.svg" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/chainsecurity.svg" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/consensys.svg" style="width: 70%" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/LeastAuthority_.png" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/manwu.png" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/OZ_logo_color (3)-1.png" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/Quantstamp.svg" style="width: 80%" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/runtimeverification.png" /></p>
                    </li>
                    <li>
                      <p><img src="@/assets/friends/Trail-of-Bits.svg" /></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
